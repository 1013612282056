
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { BLOG_POSTS_PER_PAGE } from '@app/constants';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { makeBlogStore } from '@app/stores';
import { RootModel } from '@app/stores/pages/blog';
import { fetchCommonData } from '@common/dataContext/commonData';
import { BlogLayout, Content, Sidebar } from '@main/src/new/old/Blog/Blog';
import BlogCategories from '@main/src/new/old/BlogCategories';
import BlogPostPreview from '@main/src/new/old/BlogPostPreview';
import BlogTags from '@main/src/new/old/BlogTags';
import { Breadcrumbs } from '@main/src/new/old/Breadcrumbs';
import { MiniSocial } from '@main/src/new/old/MiniSocial';
import { Pagination } from '@main/src/new/old/PaginationBlog';
import { Title } from '@main/src/new/old/Title';
import { RematchStore } from '@rematch/core';
import useTranslation from 'next-translate/useTranslation';
import { Provider } from 'react-redux';
import { IBlogResult } from 'src/types/blog/next';
import { NextPageCommon } from 'src/types/layouts/types';
import { makeBlogPageProps } from '@common/helpers/static/props';
import { GetStaticProps } from 'next/types';
import { take } from 'lodash/fp';
import { PageSEO } from '@main/src/new/old/Seo';
import { MainLayout } from '@new/layouts';
import { emptyNextRequest } from '@app/constants/emptyNextRequest';
import { OfferVideoTeam } from '@main/src/new/components/BlogPage';
import { AuthContextWrapper } from '@main/src/new/auth';
import cls from '../../new/old/Blog/Blog.module.css';
const Blog: NextPageCommon<{
    blog: IBlogResult;
}> = ({ common, blog }) => {
    const { categories, posts, tags, postsCount, page } = blog;
    const { t } = useTranslation('ui');
    const store: RematchStore<RootModel, RootModel> = makeBlogStore(common);
    return (<>
			<PageSEO />
			<AuthContextWrapper hasAnonymous={common.hasAnonymous}>
				<Provider store={store}>
					<MainLayout className={cls.wrapper}>
						<section>
							<Title title={t('blog.title')}/>
							<Breadcrumbs paths={[
            {
                link: '/',
                name: t('blog.main'),
            },
            {
                link: '/blog',
                name: t('blog.title'),
            },
        ]}/>
							<BlogLayout>
								<Content>
									{/* <BlogSearch /> */}
									{posts && posts.length > 0 ? (<>
											{posts.map(post => {
                return <BlogPostPreview key={post.id} {...post}/>;
            })}

											<Pagination totalItems={postsCount} perPage={BLOG_POSTS_PER_PAGE} page={page}/>
											<OfferVideoTeam />
										</>) : posts && posts.length === 0 ? (<div>{t('blog.noPosts')}</div>) : null}
								</Content>
								<Sidebar>
									{/* <BlogSearch /> */}
									<BlogCategories title={t('blog.categories')} categories={categories}/>
									{/* <BlogListArticles title={t('blog.topArticles')} articles={topPosts} /> */}

									<BlogTags title={t('blog.popularTags')} tags={tags}/>
									{/* <BlogJoinUs
    title={t('joinUs')}
    description='Eiusmod tempor incididunt ut labore et dolore magna aliqua exercitation ullamco laboris nisi ut aliquip exa dolor'
/> */}

									<MiniSocial vk='https://vk.com/qvedo' telegram='https://t.me/qvedo'/>
								</Sidebar>
							</BlogLayout>
						</section>
					</MainLayout>
				</Provider>
			</AuthContextWrapper>
		</>);
};
const getStaticProps: GetStaticProps = ({ locale }) => {
    const currentLocale = locale || DEFAULT_LOCALE;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const common = fetchCommonData(emptyNextRequest, null, locale, true);
    const blog = makeBlogPageProps({ locale: currentLocale });
    const posts = take(BLOG_POSTS_PER_PAGE, blog.posts);
    return {
        props: {
            common,
            blog: {
                ...blog,
                posts,
                postsCount: blog.posts.length,
                page: 1,
            },
        },
    };
};
export default Blog;

    async function __Next_Translate__getStaticProps__19249641fe7__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/blog/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19249641fe7__ as getStaticProps }
  